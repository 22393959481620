
              const Handlebars = require('handlebars');
              const templateFunction = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "\n<div id=\"services-search\">\n	<div class=\"row services-search-row\">\n		<div class=\"services-search-row-main\">\n			<div class=\"logo-container\">\n				<a href=\"/\">\n					<img src=\"/4c7993f2c96de053290dc11e4aee4979.svg\" alt=\""
    + escapeExpression(((helper = (helper = helpers.overlayDirectoryName || (depth0 != null ? depth0.overlayDirectoryName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"overlayDirectoryName","hash":{},"data":data}) : helper)))
    + "\" class=\"logo-img\">\n				</a>\n			</div>\n			<form role=\"form\" id=\"iss-search_form\">\n				<div class=\"iss-keyword-search\">\n					<label class=\"sr-only\" for=\"iss-keyword-search_input\">Services keywords</label>\n					<input type=\"text\" class=\"search-services-box form-control\" id=\"iss-keyword-search_input\" placeholder=\"Services or keywords\" size=\"60\">\n				</div>\n				<div class=\"iss-location-search\">\n					<label class=\"sr-only\" for=\"iss-location-search_input\">\n						Suburb or postcode in "
    + escapeExpression(((helper = (helper = helpers.directoryRegion || (depth0 != null ? depth0.directoryRegion : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"directoryRegion","hash":{},"data":data}) : helper)))
    + "\n					</label>\n					<input type=\"text\" class=\"input-sm search-locations-box form-control\" id=\"iss-location-search_input\" placeholder=\"Suburb or postcode\">\n				</div>\n				<button type=\"submit\" id=\"iss-search_submit\" class=\"btn btn-primary with-icon\">\n					"
    + escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "spinner.hbs", {"name":"partial","hash":{},"data":data})))
    + "\n					<i class=\"fa fa-search\"></i><span class=\"text\">Find services</span>\n				</button>\n				<div class=\"help-block\">"
    + escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "text_below_search_input.hbs", {"name":"partial","hash":{},"data":data})))
    + "\n				</div>\n			</form>\n		</div><!-- services-search-row-main -->\n\n		<div class=\"services-search-row-auxiliary\">\n			<button class=\"btn btn-primary-dark btn-auxiliary advanced_search\">\n				<div class=\"inner\">\n					<i class=\"fa fa-bullseye\"></i>\n					<div class=\"text\">Advanced<br> Search</div>\n				</div>\n			</button>\n			<button class=\"btn btn-primary-dark btn-auxiliary add-service\">\n				<div class=\"inner\">\n					<i class=\"fa fa-plus\"></i>\n					<div class=\"text\">Add<br> Service</div>\n				</div>\n			</button>\n		</div><!-- services-search-row-auxiliary -->\n	</div>\n	<div class=\"row\">\n		<div id=\"search-sidebar\">\n\n		</div>\n		<div id=\"leaflet-map\" class=\"col-lg-2\"></div>\n	</div>\n</div>\n\n";
},"useData":true});
              Handlebars.templates['src/hbs/main.hbs'] = templateFunction;
              export default templateFunction;
              