
              const Handlebars = require('handlebars');
              const templateFunction = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "\n<div id=\"as-mobile\">\n<div id=\"services-search\">\n	"
    + escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "mobile_logo_bar.hbs", {"name":"partial","hash":{},"data":data})))
    + "\n	<div class=\"row\">\n		<div id=\"search_container\">\n			<form class=\"form-inline\" role=\"form\" id=\"iss-search_form\">\n				<div class=\"search-box-mobile\">\n					<div class=\"input-group\">\n						<div class=\"iss-keyword-search\">\n							<label class=\"sr-only\" for=\"iss-keyword-search_input\">\n									Service keywords\n								</label>\n							<input type=\"text\" class=\"input-sm form-control search-services-box\" id=\"iss-keyword-search_input\" placeholder=\"Service keywords\">\n						</div>\n						<div class=\"iss-location-search\">\n							<label class=\"sr-only\" for=\"iss-location-search_input\">\n								Suburb or postcode\n							</label>\n							<input type=\"text\" class=\"input-sm form-control search-locations-box\" id=\"iss-location-search_input\" placeholder=\"Suburb or postcode\">\n						</div>\n						<span class=\"input-group-btn\">\n							<button class=\"btn btn-default\" type=\"submit\">Go!</button>\n						</span>\n					</div><!-- /input-group -->\n				</div>\n				<ul id=\"\" class=\"menu-tabs-mobile row nav\">\n					<li class=\"menu-box-mobile search-results-li hide-search-results-lnk\">\n						<a id=\"issSearchResultsMobileLnk\" href=\"#\" data-toggle=\"tab\">View list of results</a>\n					</li>\n					<li class=\"menu-box-mobile search-results-li hide-search-results-lnk\">\n						<a id=\"issAdvancedSearchMobilelnk\" href=\"#\" data-toggle=\"tab\">Advanced search</a>\n					</li>\n				</ul>\n			</form>\n		</div>\n	</div>\n</div>\n<div class=\"search-result-container\">\n	<div class=\"intro-page-mobile\">\n		<h3>"
    + escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "text_below_search_input.hbs", {"name":"partial","hash":{},"data":data})))
    + "</h3>\n	</div>\n</div>\n<div id=\"mapMobileContainer\">\n		<div id=\"leaflet-map\"></div>\n	</div>\n<div class=\"mobile-spinner\">\n	"
    + escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "spinner.hbs", {"name":"partial","hash":{},"data":data})))
    + "\n</div>\n\n</div>";
},"useData":true});
              Handlebars.templates['src/hbs/main-mobile.hbs'] = templateFunction;
              export default templateFunction;
              